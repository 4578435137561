.popup_container{
  position: absolute;
  left: 0;
  top: 0;
  width: 1280px;
  height: 720px;
  z-index: 8888;
  background: rgba(0,0,0,0.5);
  transition: all ease-in-out 0.5s;
  display: none;



  > .popup{
    position: relative;
    width: 760px;
    height: 620px;
    margin: 30px auto;
    background-color: #F0F0F0;
    border-radius: 20px;
    padding: 20px;
    &.exit_popup{
      width: 400px;
      height: 300px;
      margin-top: 210px;
       .popup_content{
         height: 220px;
       }
      .popup_actions{
        width: 400px;
        .button{
          width: 50px;
        }
      }
      .exit_title{
        font-size: 36px !important;
        margin-bottom: 30px !important;
      }
      .exit_text{
        font-size: 24px;
        text-align: center;
        margin-top: 70px;
      }
    }

    .popup_content{
      height: 555px;
      margin-bottom: 15px;
      .qr_title, .exit_title{
        display: block;
        font-size: 24px;
        height: 30px;
        line-height: 30px;
        font-weight: 600;
        margin-bottom: 15px;
      }
      .qr_QrContainer{
        width: 760px;
        height: 430px;

        margin-top: 40px;
        overflow: hidden;
        .qr_QrText{
          position: relative;
          float: left;
          width: 260px;
          text-align: right;
          overflow: hidden;

          span{
            font-size: 30px;
          }
          > img{
            margin-top: 75px;
            width: 200px;
          }
        }
        .qr_QrCode {
          position: relative;
          width: 450px;
          height: 420px;
          float: right;
          overflow: hidden;
          > img{
            width: 360px;
          }
          > canvas {
            position: absolute;
            width: 300px !important;
            height: 300px !important;
            top: 0;
            right: 80px;
            border-radius: 10px;
            -webkit-box-shadow: 0 3px 1px -2px #0003, 0 2px 2px #00000024, 0 1px 5px #0000001f;
            -moz-box-shadow: 0 3px 1px -2px #0003, 0 2px 2px #00000024, 0 1px 5px #0000001f;
            box-shadow: 0 3px 1px -2px #0003, 0 2px 2px #00000024, 0 1px 5px #0000001f;
          }
        }
      }
    }
    .popup_actions{
      width: 760px;
      height: 40px;
      text-align: center;
       .button{
         border-radius: 4px;
        height: 40px;
        line-height: 40px;
        background-color: #666666;
         border: 1px solid #666666;
        display: inline-block;
        color: #F1F1F1;
        padding: 0 10px;
         margin: 0 5px;
         &:hover{
           background-color: #333333;
           border-color: #D20D0F;
         }
         &.button_active{
           background-color: #D20D0F;
           border-color: #D20D0F;
           &:hover{
             border-color: #333333;
           }
         }
      }
    }

  }

}
@media screen and (min-width: 1920px){
  .popup_container{
    width: 1920px;
    height: 1080px;
    > .popup{
      width: 1140px;
      height: 930px;
      margin: 45px auto;
      border-radius: 30px;
      padding: 30px;
      &.exit_popup{
        width: 600px;
        height: 450px;
        margin-top: 315px;
        .popup_content{
          height: 330px;
        }
        .popup_actions{
          width: 600px;
          .button{
            width: 75px;
          }
        }
        .exit_title{
          font-size: 54px !important;
          margin-bottom: 45px !important;
        }
        .exit_text{
          font-size: 34px;
          text-align: center;
          margin-top: 105px;
        }
      }

      .popup_content{
        height: 832px;
        margin-bottom: 22px;
        .qr_title, .exit_title{
          display: block;
          font-size: 36px;
          height: 45px;
          line-height: 45px;
          font-weight: 600;
          margin-bottom: 22px;
        }
        .qr_QrContainer{
          width: 1140px;
          height: 645px;

          margin-top: 60px;
          .qr_QrText{
            width: 390px;
            span{
              font-size: 45px;
            }
            > img{
              margin-top: 112px;
              width: 300px;
            }
          }
          .qr_QrCode {
            width: 675px;
            height: 630px;
            > img{
              width: 540px;
            }
            > canvas {
              position: absolute;
              top: 0;
              right: 120px;
              border-radius: 15px;
              width: 450px !important;
              height: 450px !important;
            }
          }
        }
      }
      .popup_actions{
        width: 1140px;
        height: 60px;
        .button{
          height: 60px;
          line-height: 60px;
          padding: 0 15px;
          margin: 0 7px;
        }
      }

    }

  }
}
#popup_placeholder {
  .popup_container{
    display: block !important;
  }
}