.contentContainer {
  .search_area{
    width: 1110px;
    height: 250px;
    overflow: hidden;

    .keyboard_area{
      float: right;
      margin-right: 300px;
      width: 345px;
      height: 250px;
      position: relative;
    }

    .input_area{
      border: 1px solid white;
      float: left;
      width: 450px;
      height: 50px;
      line-height: 50px;
      margin-top: 60px;
      background-color: #FFFFFF;

      #inputField{
        padding: 0 10px;
        position: relative;
        /*height: 130px;
        margin-top: 85px;
        margin-left: 10px;
        height: 32px;
        line-height: 30px;
        border-bottom: 1px solid #F8F8F6;
        width: 400px;
        padding: 5px 0px;
        position: relative;*/

        #searchPlaceholder{
          position: absolute;
          left: 0;
          top: 10px;
          width: 440px;
          height: 32px;
          line-height: 32px;
          opacity: 0.5;
          display: block;
          text-align: center;
        }

        #searchText{
          font-size: 32px;
          font-weight: bold;
          margin-right: 3px;
        }
      }
    }

    #search_result_count{
      margin-top: 20px;
      font-size: 19px;
      line-height: 24px;
      margin-left: 10px;
      width: 560px;
      display: block;
    }
  }
  .result_area{
    position: relative;
    width: 1110px;
    padding-bottom: 30px;
    height: 400px;

    box-sizing: border-box;

     .result_menu{
      position: absolute;
       top: -50px;
        .smenu{
           display: inline-block;
           padding: 0 10px;
           margin-right: 10px;
           background-color: #808080;
           color: #F1F1F1;
           height: 40px;
           line-height: 40px;
           font-size: 20px;
           border: 2px solid transparent;
            box-sizing: border-box;

          &:hover{
            border-color: #D20D0F;
            border-style: dashed;
            &.smenu_active{
              border-color: #D20D0F;
              background-color: #808080;
            }
          }
          &.smenu_visited{
            background-color: #333333;
          }

          &.smenu_active{
            background-color: #D20D0F;
          }
       }
    }


    .search_results{
      -moz-transition: margin ease .5s;
      -webkit-transition: margin ease .5s;
      transition: margin ease .5s;
      height: 400px;
      overflow: hidden;
      .results{
        display: none;
        transition: all ease .3s;
        width: 1110px;
        min-height: 100%;
        position: relative;
      }
    }

    #sloadMore {
      display: none;
    }
    .resultplaceholder{
      color: #F8F8F6;
      width: 600px;
      border: 0px solid white;
      text-align: center;
      margin: 120px auto 0 auto;
    }


  }

  #searchLoader{
    position: absolute;
    z-index: 8888;
    top: 50%;
    left: 50%;
    background: rgba(0,0,0,0.8);
    display: none;
  }
}

@media screen and (min-width: 1920px){
  .contentContainer {
    .search_area{
      width: 1665px;
      height: 375px;

      .keyboard_area{
        margin-right: 450px;
        width: 517px;
        height: 375px;
      }

      .input_area{
        width: 675px;
        height: 75px;
        line-height: 75px;
        margin-top: 90px;

        #inputField{
          padding: 0 15px;

          #searchPlaceholder{
            top: 15px;
            width: 660px;
            height: 48px;
            line-height: 48px;
          }

          #searchText{
            font-size: 48px;
            margin-right: 4px;
          }
        }
      }

      #search_result_count{
        margin-top: 30px;
        font-size: 29px;
        line-height: 36px;
        margin-left: 15px;
        width: 840px;
      }
    }
    .result_area{
      width: 1665px;
      padding-bottom: 45px;
      height: 600px;
      .result_menu{
        position: absolute;
        top: -75px;
        .smenu{
          padding: 0 15px;
          margin-right: 15px;
          height: 60px;
          line-height: 60px;
          font-size: 30px;
        }
      }


      .search_results{
        height: 600px;
        overflow: hidden;
        .results{
          width: 1665px;
        }
      }

      .resultplaceholder{
        width: 900px;
        margin: 180px auto 0 auto;
      }
    }
  }
}