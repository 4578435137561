.splashScreen {
  width: 1280px;
  height: 720px;
  position: relative;
  overflow: hidden;
  background-color: #FAFAFA;
}
.splashScreen > .logo{
  width: 1280px;
  height: 100px;
  overflow: hidden;
  box-sizing: border-box;
  text-align: center;
}
.splashScreen > .logo > img{
  height: 55px;
  margin-top: 30px;
}
.splashScreen .splashLoader{
  height: 520px;
  position: relative;
}
.splashScreen .splashLoader > .loading{
  position: absolute;
  top: 50%;
  left: 50%;
  margin-left: -50px;
  margin-top: -50px;
  border: 10px !important;
}
.splashScreen .splashLoader > .loading .sk-spinner{
  color: #D20D0F;
}
.ball-clip-rotate > div{
  width: 100px !important;
  height: 100px !important;
}
.splashScreen > .error{
  color: #D20D0F;
  width: 1160px;
  position: absolute;
  bottom: 100px;
  left: 60px;
  text-align: center;
}
.MuiCircularProgress-root{
  color: #D20D0F !important;
}

.scrollTop_container{
  position: absolute;
  z-index: 2;
  width: 1280px;
  height: 40px;
  background-color: transparent;
  top: 0px;
  left: 0;
}
.scrollDown_container{
  position: absolute;
  z-index: 2;
  width: 1280px;
  height: 40px;
  background-color: transparent;
  bottom: 0px;
  left: 0;
}

@media screen and (min-width: 1920px){
  .splashScreen {
    width: 1920px;
    height: 1080px;
  }
  .splashScreen > .logo{
    width: 1920px;
    height: 150px;
  }
  .splashScreen > .logo > img{
    height: 83px;
    margin-top: 45px;
  }
  .splashScreen .splashLoader{
    height: 780px;
  }
  .splashScreen > .error{
    width: 1800px;
    bottom: 150px;
  }
}