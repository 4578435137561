.sidenav_container {
  background-color: rgba(246,242,242,1);
  background-image: linear-gradient(315deg, rgba(246,242,242,0.7) 0%, rgba(227,239,232,0.7) 74%);
  box-sizing: content-box;
  position: absolute;
  top: 0;
  left: 0;
  padding-left: 60px;
  max-width: 45px;
  font-size: 20px;
  height: 720px;
  z-index: 5;
  overflow: hidden;
  -moz-transition: 0.3s;
  -webkit-transition: 0.3s;
  transition: 0.3s;
  -webkit-box-shadow: 2px -1px 5px 0px #333333;
  -moz-box-shadow: 2px -1px 5px 0px #333333;
  box-shadow: 2px -1px 5px 0px #333;
  overflow-y: hidden;

  .appLogo{
    position: absolute;
    left: 40px;
    top: 75px;
    max-width: 60px;
    transition: all ease 0.4s;
  }

  &.open{
    max-width: 270px;
    padding-right: 10px;
    .appLogo{
      left: 120px;
      max-width: 130px;
      top: 30px;
    }
  }

  > .menu_container{
    height: 500px;
    margin-top: 175px;
    width: 345px;
    box-sizing: border-box;
    overflow: hidden;

    .menu{
      display: block;
      height: 40px;
      margin-top: 5px;
      line-height: 40px;
      text-decoration: none;
      color: #808080;

      &.home{
        margin-top: 40px;
      }

      &.exit{
        margin-top: 30px;
      }

      >.icon{
        vertical-align: top;
        display: inline-block;
        margin-right: 10px;
        font-size: 28px;
        line-height: 40px;
        width: 40px;
        height: 40px;
        overflow: hidden;
        text-align: center;
        .material-icons,
        .material-icons-outlined{
          font-size: 32px;
          margin: 6px auto 0 auto;
        }
        img{
          height: 23px;
          margin: 12px auto 0 auto;
          display: none;
          &.small{
            height: 21px;
          }
        }

        .icon_normal{
          display: block;
        }

        svg{
          font-size: 28px;
        }
      }
      >.menu_title{
        width: 200px;
        display: inline-block;
        padding: 0 10px;
        vertical-align: middle;
        height: 40px;
        line-height: 40px;
        overflow: hidden;
        border: 1px transparent dashed;
      }
      &:hover,
      &.menu_hover{
        > .icon{
          .icon_normal,
          .icon_visited,
          .icons_active{
            display: none;
          }
          .icon_hover{
            display: block;
          }
          .material-icons,
          .material-icons-outlined{
            color: #D20D0F;
          }
        }
        img {
          height: 23px;
          margin: 12px auto 0 auto;
          display: none;
          &.small{
            height: 21px;
          }
        }
        svg,
        i{
          color: #D20D0F;
        }
        >.menu_title{
          border-color: #D20D0F;
        }
      }

      &.menu_active{
        svg,
        i{
          color: #D20D0F;
        }
        > .icon{
          .icon_normal,
          .icon_visited,
          .icon_hover{
            display: none;
          }
          .icon_active{
            display: block;
          }
          .material-icons,
          .material-icons-outlined{
            color: #D20D0F;
          }
        }

        >.menu_title{
          background-color: #D20D0F;
          border-radius: 5px;
          color: #F1F1F1;
        }
      }

      &.menu_visited{
        svg,
        i{
          color: #333333;
        }
        > .icon{
          .icon_normal,
          .icon_active,
          .icon_hover{
            display: none;
          }
          .icon_visited{
            display: block;
          }
          .material-icons,
          .material-icons-outlined{
            color: #333333;
          }
        }
        >.menu_title{
          background-color: #333333;
          color: #F1F1F1;
          border-radius: 5px;
        }
      }

      &.current{
        svg,
        i{
          color: #D20D0F;
        }
      }
    }
  }
}

@media screen and (min-width: 1920px){
  .sidenav_container {
    max-width: 67px;
    font-size: 30px;
    height: 1080px;

    .appLogo{
      left: 40px;
      top: 75px;
      max-width: 90px;
    }

    &.open{
      max-width: 405px;
      padding-right: 15px;
      .appLogo{
        left: 180px;
        max-width: 195px;
        top: 45px;
      }
    }

    > .menu_container{
      height: 750px;
      margin-top: 262px;
      width: 517px;

      .menu{
        height: 60px;
        margin-top: 7px;
        line-height: 60px;
        &.search{
          margin-bottom: 60px;
        }

        &.exit{
          margin-top: 45px;
        }

        >.icon{
          margin-right: 15px;
          font-size: 42px;
          line-height: 60px;
          width: 60px;
          height: 60px;
          .material-icons,
          .material-icons-outlined{
            font-size: 48px;
            margin: 9px auto 0 auto;
          }

          img {
            height: 35px;
            margin: 18px auto 0 auto;
            display: none;
            &.small{
              height: 32px;
            }
          }

          .icon_normal{
            display: block;
          }

          svg{
            font-size: 42px;
          }
        }
        >.menu_title{
          width: 300px;
          padding: 0 15px;
          height: 60px;
          line-height: 60px;
        }


        &.menu_active{
          >.menu_title{
            border-radius: 7px;
          }
        }

        &.menu_visited{
          >.menu_title{
            border-radius: 7px;
          }
        }
      }
    }
  }
}