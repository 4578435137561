@font-face {
  font-family: 'Material Icons';
  font-style: normal;
  font-weight: 400;
  src: url("fonts/MaterialIcons-Regular.eot"); /* For IE6-8*/
  src: local('Material Icons'),
  local('MaterialIcons-Regular'),
  url("fonts/MaterialIcons-Regular.woff2") format('woff2'),
  url("fonts/MaterialIcons-Regular.woff") format('woff'),
  url("fonts/MaterialIcons-Regular.ttf") format('truetype');
}

@font-face {
  font-family: 'Material Icons Outlined';
  font-style: normal;
  font-weight: 400;
  src: local('Material Icons Outlined'),
  local('MaterialIconsOutlined-Regular'),
  url("fonts/MaterialIconsOutlined-Regular.woff2") format('woff2'),
  url("fonts/MaterialIconsOutlined-Regular.woff") format('woff'),
  url("fonts/MaterialIconsOutlined-Regular.ttf") format('truetype');
}

/* Roboto-regular */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  src: local('Roboto Regular'), local('Roboto-Regular'),
  url('fonts/Roboto-Regular.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
  url('fonts/Roboto-Regular.woff') format('woff'), /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
  url("fonts/Roboto-Regular.ttf") format('truetype');
}

/* Roboto-Italic */
@font-face {
  font-family: 'Roboto';
  font-style: italic;
  font-weight: 400;
  src: local('Roboto Italic'), local('Roboto-Italic'),
  url('fonts/Roboto-Italic.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
  url('fonts/Roboto-Italic.woff') format('woff'), /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
  url("fonts/Roboto-Italic.ttf") format('truetype');
}

/* Roboto-Bold */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: bold;
  src: local('Roboto Bold'), local('Roboto-Bold'),
  url('fonts/Roboto-Bold.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
  url('fonts/Roboto-Bold.woff') format('woff'), /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
  url("fonts/Roboto-Bold.ttf") format('truetype');
}

/* Roboto-BoldItalic */
@font-face {
  font-family: 'Roboto';
  font-style: italic;
  font-weight: bold;
  src: local('Roboto Bold Italic'), local('Roboto-BoldItalic'),
  url('fonts/Roboto-BoldItalic.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
  url('fonts/Roboto-BoldItalic.woff') format('woff'), /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
  url("fonts/Roboto-BoldItalic.ttf") format('truetype');
}

.material-icons{
  font-family: "Material Icons";
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  display: inline-block;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;

  /* Support for IE. */
  font-feature-settings: 'liga';
}

.material-icons-outlined {
  font-family: 'Material Icons Outlined';
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  -webkit-font-feature-settings: 'liga';
  -webkit-font-smoothing: antialiased;
}


*{
  margin: 0;
  padding: 0;
}
body {
  position: relative;
  background: transparent;
  font-size: 18px;
  width: 1280px;
  height: 720px;
  overflow: hidden;
  box-sizing: border-box;
  font-family: Roboto,"sans-serif";
  color: #333333;
}

#trace{
  position: absolute;
  display: block;
  bottom: 30px;
  right: 60px;
  max-width: 1160px;
  color: #F0F0F0;
  background-color: #D20D0F;
  padding: 5px;
  z-index: 9999;
  display: block;
}
.clear{
  clear: both;
}

.errorBox{
  position: absolute;
  z-index: 1000;
  top: 50%;
  left: 50%;
  width: 400px;
  height: 200px;
  background-color: #D20D0F;
  color: #F0F0F0;
  padding: 30px 20px;
  line-height: 60px;
  font-size: 30px;
  text-align: center;
  margin-left: -220px;
  margin-top: -130px;
  display: none;
}

.materialized{
  box-shadow: 0 3px 1px -2px #0003, 0 2px 2px #00000024, 0 1px 5px #0000001f;
  transform: translate3d(0, 0, 0);
  transition: background 400ms cubic-bezier(0.25, 0.8, 0.25, 1),box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1);
}

@media screen and (min-width: 1920px){
  body{
    width: 1920px;
    height: 1080px;
    font-size: 27px;
    overflow: hidden;
  }
  trace{
    max-width: 1800px;
  }
  .errorBox{
    width: 600px;
    height: 300px;
    margin-left: -330px;
    margin-top: -195px;
    line-height: 90px;
    font-size: 45px;
  }
}