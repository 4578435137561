#app{
  width: 1280px;
  height: 720px;
  overflow: hidden;
}

.container{
  position: relative;
  width: 1280px;
  height: 720px;
  z-index: 0;
  vertical-align: top;
  overflow: hidden;
  background-color: #F1F1F1;

  &.nomenu{
    z-index: 1000;
  }
  &.mainContainer{
    width: 1170px;
    height: 660px;
    padding-top: 30px;
    padding-bottom: 30px;
    padding-left: 110px;

    &.sopen{
      .submenu{
        padding-left: 20px;
        max-width: 250px;
      }

      > div{
        margin-left: 280px;
      }
    }

    .submenu{
      background-color: rgba(246,242,242,1);
      background-image: linear-gradient(315deg, rgba(246,242,242,0.7) 0%, rgba(227,239,232,0.7) 74%);
      box-sizing: content-box;
      position: absolute;
      top: 0;
      left: 108px;
      max-width: 00px;
      height: 720px;
      color: #F0F0F0;
      font-size: 20px;
      z-index: 5;
      overflow: hidden;
      -moz-transition: 0.5s;
      -webkit-transition: 0.5s;
      transition: 0.5s;
      -webkit-box-shadow: 2px -1px 5px 0px #333333;
      -moz-box-shadow: 2px -1px 5px 0px #333333;
      box-shadow: 2px -1px 5px 0px #333;


      .submenu_content{
        height: 490px;
        width: 250px;
        margin-top: 180px;
        overflow: hidden;

        .submenu_item{
          padding: 5px;
          line-height: 30px;
          margin-bottom: 10px;
          color: #333333;
          width: 230px;
          border-radius: 5px;

          &:hover{
            background-color: #333333;
            color: #F1F1F1;
          }

          &.submenu_item_active{
            background-color: #D20D0F;
            color: #F1F1F1;
          }

          &.submenu_item_visited{
            background-color: #666666;
            color: #F1F1F1;
          }
        }
      }
    }

    .GridTitle{
      position: relative;
      font-size: 26px;
      text-transform: uppercase;
      color: #D20D0F;
      height: 40px;
      margin-left: 20px;
    }

    .contentContainer{
      margin-left: 10px;
      overflow: hidden;
      width: 1110px;
      margin-left: 20px;
      position: relative;
      -moz-transition: 0.5s;
      -webkit-transition: 0.5s;
      transition: 0.5s;
      &.emptyContent{
        text-align: center;
        margin-top: 290px;
      }
      > div{
        position: relative;
        -moz-transition: 0.5s;
        -webkit-transition: 0.5s;
        transition: 0.5s;
      }
      .fulltext {
        h2{
          font-size: 22px;
          line-height: 28px;
          margin: 10px 0;
        }
        p{
          font-size: 18px;
          line-height: 30px;
        }
      }
    }
  }
}

.preloader{
  position: relative;
  width: 1280px;
  height: 720px;

  >div{
    position: absolute;
    top: 50%;
    left: 50%;
    margin-left: -40px;
    margin-top: -40px;
  }
}
.hide{
  display: none;
}
.loader{
  position: relative;
  width: 100%;
  height: 100%;
  > div{
    position: absolute;
    top: 50%;
    left: 50%;
    margin-left: -40px;
    margin-top: -40px;
  }
}

.grid_loader{
  width: 252px;
  height: 220px;
  margin-bottom: 17px;
  padding-left: 8px;
  padding-right: 8px;
  float: left;
  background-color: #FFFFFF;
  overflow:hidden;
  border-radius: 5px;
  position: relative;
}

.grid{
  width: 276px;
  height: 236px;
  float: left;
  overflow:hidden;
  position: relative;
  box-sizing: border-box;

  >.grid_content{
    width: 250px;
    height: 220px;
    margin: 8px 13px;

    border-radius: 5px;
    overflow: hidden;
    position: relative;

    &:hover{
      width: 276px;
      height: 236px;
      margin: 0;
      background-color: #FFFFFF;
      .grid_img{
        width: 276px;
        height: 155px;
        position: relative;
        overflow: hidden;

        > div{
          width: 276px;
          height: 155px;
          padding-top: 0!important;
        }
      }
    }

    .grid_img{
      width: 250px;
      height: 140px;
      position: relative;
      overflow: hidden;

      > div{
        width: 250px;
        height: 140px;
        padding-top: 0!important;
      }
      > span.catname{
        position: absolute;
        bottom: 5px;
        left: 5px;
        background-color: rgba(255,255,255,0.9);
        padding: 3px 5px;
        height: 18px;
        font-size: 16px;
        font-weight: 600;
      }
    }
    .grid_title{
      padding: 5px;
      height: 48px;
      line-height: 18px;
      overflow: hidden;
      width: 240px;
      font-size: 16px;
      font-weight: 600;
      color: #D20D0F;
      word-break: break-word;
    }
    .grid_date {
      padding: 0 5px;
      font-size: 14px;
      line-height: 18px;
      height: 20px;
      overflow: hidden;
    }
  }


  &.grid_active{
    box-shadow: 0 3px 1px -2px #0003, 0 2px 2px #00000024, 0 1px 5px #0000001f;
    transform: translate3d(0, 0, 0);
    transition: background 400ms cubic-bezier(0.25, 0.8, 0.25, 1),box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1);
    >.grid_content{
      width: 276px;
      height: 236px;
      background-color: #FFFFFF;
      border: 2px #D20D0F solid;
      box-sizing: border-box;
      margin: 0;
      .grid_img{
        width: 276px;
        height: 155px;
        position: relative;
        overflow: hidden;

        > div{
          width: 276px;
          height: 155px;
          padding-top: 0!important;
        }
      }
    }
  }
}

@media screen and (min-width: 1920px){
  #app{
    width: 1920px;
    height: 1080px;
  }

  .container{
    width: 1920px;
    height: 1080px;

    &.mainContainer{
      width: 1755px;
      height: 1020px;
      padding-top: 30px;
      padding-bottom: 30px;
      padding-left: 165px;

      &.sopen{
        .submenu{
          padding-left: 30px;
          max-width: 375px;
        }

        > div{
          margin-left: 390px;
        }
      }

      .submenu{
        left: 130px;
        max-width: 00px;
        height: 1080px;
        color: #F0F0F0;
        font-size: 30px;
        .submenu_content{
          height: 735px;
          width: 375px;
          margin-top: 270px;

          .submenu_item{
            padding: 7px;
            line-height: 45px;
            margin-bottom: 15px;
            width: 345px;
          }
        }
      }

      .GridTitle{
        font-size: 39px;
        height: 60px;
        margin-left: 30px;
      }

      .contentContainer{
        margin-left: 15px;
        width: 1665px;
        margin-left: 30px;
        &.emptyContent{
          text-align: center;
          margin-top: 435px;
        }
        .fulltext {
          h2{
            font-size: 33px;
            line-height: 42px;
            margin: 15px 0;
          }
          p{
            font-size: 27px;
            line-height: 45px;
          }
        }
      }
    }
  }

  .preloader{
    width: 1920px;
    height: 1080px;

    >div{
      position: absolute;
      top: 50%;
      left: 50%;
      margin-left: -40px;
      margin-top: -40px;
    }
  }

  .grid_loader{
    width: 378px;
    height: 330px;
    margin-bottom: 25px;
    padding-left: 12px;
    padding-right: 15px;
  }

  .grid{
    width: 414px;
    height: 354px;
    >.grid_content{
      width: 375px;
      height: 330px;
      margin: 12px 19px;

      border-radius: 7px;

      &:hover{
        width: 414px;
        height: 354px;
        .grid_img{
          width: 414px;
          height: 233px;

          > div{
            width: 414px;
            height: 233px;
            padding-top: 0!important;
          }
        }
      }

      .grid_img{
        width: 375px;
        height: 210px;

        > div{
          width: 375px;
          height: 210px;
          padding-top: 0!important;
        }
        > span.catname{
          position: absolute;
          bottom: 7px;
          left: 7px;
          padding: 4px 7px;
          height: 27px;
          font-size: 24px;
          font-weight: 600;
        }
      }
      .grid_title{
        padding: 7px;
        height: 72px;
        line-height: 27px;
        overflow: hidden;
        width: 360px;
        font-size: 24px;
      }
      .grid_date {
        padding: 0 7px;
        font-size: 21px;
        line-height: 27px;
        height: 30px;
      }
    }


    &.grid_active{
      >.grid_content{
        width: 414px;
        height: 354px;
        .grid_img{
          width: 414px;
          height: 233px;

          > div{
            width: 414px;
            height: 233px;
            padding-top: 0!important;
          }
        }
      }
    }
  }
}