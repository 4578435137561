.infobox {
  position: absolute;
  z-index: 100;
  width: 640px;
  height: 660px;
  padding: 30px 60px 30px 20px;
  top: 0;
  right: 0;
  left: auto;
  background: rgba(0,0,0,.8);
  color: #fff;
  display: none;
  overflow: hidden;

  .btn{
    background-color: #f1f1f1;
    color: #666666;

    &.btn_active{
      background-color: #D20D0F;
      color: #f1f1f1;
    }
  }

  .titlebox {
    height: 140px;
    width: 640px;
    margin-bottom: 15px;
    font-size: 0;
    > div{
      display: inline-block;
      vertical-align: top;
    }

    .imgarea{
      width: 250px;
      margin-right: 13px;
      img{
        width: 250px;
      }
    }

    .titlearea{
      width: 355px;
      box-sizing: border-box;
      font-size: 18px;
    }
  }
  .infocategory{
    font-size: 18px;
    font-weight: 600;
    color: #D20D0F;
    margin-bottom: 5px;
  }
  .infotitle {
    font-size: 30px;
    font-weight: bold;
    margin-bottom: 15px;
    height: 75px;
  }
  .infocaption {
    > div{
      display: inline-block;
    }
  }
  .fleft{
    float: left;
  }
  .fright{
    float: right;
  }
  .spacer {
    margin: 0 5px;
  }
  .message {
    width: 640px;
    box-sizing: border-box;
    height: 415px !important;
    margin-bottom: 15px;
    margin-top: 5px;
    overflow: hidden;
  }
  .infobox_actions {
    width: 150px;
    height: 100px;
    margin-left: 245px;
  }

  .scrollactions {
    width: 150px;
    height: 40px;
    margin-bottom: 5px;
  }
  .buttonup {
    background-color: rgba(102,102,102,.3);
    background-position: center center;
    position: relative;
    margin-right: 2px;
    background-repeat: no-repeat;
  }
  .buttondown {
    background-color: rgba(102,102,102,.3);
    background-position: center center;
    position: relative;
    margin-left: 2px;
    background-repeat: no-repeat;
  }

  .closeinfobox {
    width: 149px;
    height: 40px;
  }
  .closeinfobox {
    border: 1px solid red;
    border-radius: 4px;
    > div {
      width: 149px;
      height: 40px;
      line-height: 40px;
      text-align: center;
    }
  }
}

@media screen and (min-width: 1920px){
  .infobox {
    width: 960px;
    height: 990px;
    padding: 45px 90px 45px 30px;

    .titlebox {
      height: 210px;
      width: 960px;
      margin-bottom: 22px;

      .imgarea{
        width: 375px;
        margin-right: 19px;
        img{
          width: 375px;
        }
      }

      .titlearea{
        width: 532px;
        font-size: 27px;
      }
    }
    .infocategory{
      font-size: 27px;
      font-weight: 600;
      margin-bottom: 7px;
    }
    .infotitle {
      font-size: 45px;
      margin-bottom: 22px;
      height: 112px;
    }
    .spacer {
      margin: 0 7px;
    }
    .message {
      width: 960px;
      height: 622px !important;
      margin-bottom: 22px;
      margin-top: 7px;
    }
    .infobox_actions {
      width: 225px;
      height: 150px;
      margin-left: 367px;
    }

    .scrollactions {
      width: 225px;
      height: 60px;
      margin-bottom: 7px;
    }
    .buttonup {
      margin-right: 3px;
    }
    .buttondown {
     margin-left: 3px;
    }

    .closeinfobox {
      width: 224px;
      height: 60px;
    }
    .closeinfobox {
      border: 1px solid red;
      > div {
        width: 224px;
        height: 60px;
        line-height: 60px;
      }
    }
  }
}