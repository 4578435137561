.apps_container{
  width: 820px;
  height: 590px;
  margin-left: 240px;
  overflow: hidden;
  box-sizing: border-box;

  >.apps_list{
    width: 820px;
    height: 490px;

    >.app_holder{
      font-size: 0;
      width: 160px;
      height: 160px;
      margin: 0;
      display: inline-block;
      box-sizing: border-box;
      overflow: hidden;

      .appGrid {
        width: 140px;
        height: 140px;
        border: 2px solid transparent;
        box-sizing: border-box;
        margin: 10px;
        -webkit-box-shadow: 0 3px 1px -2px #0003, 0 2px 2px #00000024, 0 1px 5px #0000001f;
        -moz-box-shadow: 0 3px 1px -2px #0003, 0 2px 2px #00000024, 0 1px 5px #0000001f;
        box-shadow: 0 3px 1px -2px #0003, 0 2px 2px #00000024, 0 1px 5px #0000001f;
        background-color: #ffffff;
        transition: ease linear .2s;
        border-radius: 10px;
        overflow: hidden;
        padding: 15px;
        position: relative;

        img{
          width: 106px !important;
          height: 106px !important;
        }

        > div{

        }
        &:hover,
        &.appGrid_hover{
          width: 150px;
          height: 150px;
          margin: 5px;
          border-color: #D20D0F;
          border-style: dashed;
          img{
            width: 116px !important;
            height: 116px !important;
          }
          > div{

          }
        }

        &.appGrid_active{
          width: 150px;
          height: 150px;
          margin: 5px;
          border-color: #D20D0F;
          border-style: solid;
          img{
            width: 116px !important;
            height: 116px !important;
          }
          > div{

          }
        }
      }
    }
  }

  .action_buttons{
    width: 810px;
    height: 70px;
    border: 0px solid green;
    margin-top: 30px;

    .appc{
      float: left;
      width: 60px;
      height: 60px;
      border: 1px solid #666666;
      background-color: #666666;
      margin-left: 20px;
      border-radius: 50px;
      color: white;
      line-height: 60px;
      font-size: 25px;
      text-align: center;
      font-weight: 600;
      &:hover{
        background-color: #333333;
        border-color: #D20D0F;
      }
      .material-icons,
      .material-icons-outlined{
        font-size: 40px;
        margin-top: 10px;
      }

      &.appc_active{
        background-color: #D20D0F;
        border-color: #D20D0F;
        &:hover{
          border-color: #333333;
        }
      }
      img{
        margin-top: 10px;
        height: 40px;
      }
      svg{
        font-size: 40px;
        margin: 10px auto;
      }

      &.exit{
        float: right;
      }
    }
  }

  >.imprint_container{
    position: absolute;
    z-index: 3;
    left: 0;
    bottom: 0;
    width: 1280px;
    height: 0px;
    background-color: #FAFAFA;
    -webkit-transition: height ease-in-out 0.2s;
    -moz-transition: height ease-in-out 0.2s;
    transition: height ease-in-out 0.2s;

    &.active{
      height: 620px;
    }

    >.imprint_area{
      position: relative;
      width: 1160px;
      height: 590px;
      margin-left: 60px;

      >.imp_menu{
        display: block;
        text-align: center;
        height: 40px;
        padding-bottom: 10px;
        line-height: 40px;
        margin-bottom: 10px;

        >.apps_imprint{
          display: inline-block;
          margin: 0 10px;
          padding: 0 10px;
          height: 40px;
          line-height: 40px;
          background-color: #666666;
          color: #FAFAFA;
          border: 2px solid #666666;
          box-sizing: content-box;
          border-radius: 6px;

          &:hover,
          &.apps_ihover{
            border-color: #D20D0F;
            border-style: solid;
            background-color: #333333;
          }
          &.apps_iactive{
            border-color: #D20D0F;
            background-color: #D20D0F;
            &:hover{
              background-color: #D20D0F;
              border-color: #333333;
            }
          }
        }
      }
    }
    .imp_content {
      width: 1160px;
      height: 540px;
      box-sizing: border-box;
      overflow: hidden;

      >.imprint_content{
        width: 1160px;
        height: 540px;
        display: none;

        &:first-child{
          display: block;
        }
      }

      #imp_content_general{
        width: 460px;
        margin: 10px auto 0 auto;
        font-size: 28px;
        text-align: center;
        line-height: 50px;

        >div{
          padding-top: 20px;
          padding-bottom: 20px;
          border-top: 1px solid gray;
          border-bottom: 1px solid gray;
        }
        >.mabb_logo{
          margin-top: 20px;
        }
      }
      .html_text {
        position: relative;
        line-height: 30px;
        width: 780px;
        padding-left: 10px;
        padding-right: 10px;
        margin-left: auto;
        margin-right: auto;
        text-align: justify;

        h1{
          margin-top: 10px;
          margin-bottom: 15px;
        }
        strong{
          margin: 10px 0;
        }
        p{
          margin-bottom: 15px;
        }
        a{
          color: #333333
        }
      }
      .imprint_QrContainer{
        width: 800px;
        margin: 0 auto;
        overflow: hidden;
      }

      .imprint_QrCode{
        margin-top: 30px;
        position: relative;
        width: 450px;
        height: 420px;
        overflow: hidden;
        float: right;
      }
      .imprint_QrCode > img {
        height: 400px;
      }
      .imprint_QrCode > canvas {
        width: 300px !important;
        height: 300px !important;
        position: absolute;
        top: 0;
        right: 80px;
        border-radius: 10px;
        -webkit-box-shadow: 0 3px 1px -2px #0003, 0 2px 2px #00000024, 0 1px 5px #0000001f;
        -moz-box-shadow: 0 3px 1px -2px #0003, 0 2px 2px #00000024, 0 1px 5px #0000001f;
        box-shadow: 0 3px 1px -2px #0003, 0 2px 2px #00000024, 0 1px 5px #0000001f;
      }
      .imprint_QrText {
        margin-top: 30px;
        position: relative;
        float: left;
        width: 300px;
        text-align: right;
        overflow: hidden;
      }
      .imprint_QrText span {
        font-size: 30px;
      }
      .imprint_QrText > img {
        margin-top: 10px;
        width: 200px;
      }

      .imprint_qr_tittle{
        margin-top: 50px;
        text-align: center;
      }
    }
  }
}

@media screen and (min-width: 1920px){
  .apps_container{
    width: 1230px;
    height: 885px;
    margin-left: 345px;

    >.apps_list{
      width: 1230px;
      height: 735px;

      >.app_holder{
        font-size: 0;
        width: 240px;
        height: 240px;
        .appGrid {
          width: 210px;
          height: 210px;
          margin: 10px;
          padding: 22px;
          img{
            width: 159px !important;
            height: 159px !important;
          }
          > div{

          }
          &:hover,
          &.appGrid_hover{
            width: 225px;
            height: 225px;
            margin: 5px;

            img{
              width: 174px !important;
              height: 174px !important;
            }

            > div{

            }
          }

          &.appGrid_active{
            width: 225px;
            height: 225px;
            margin: 5px;
            img{
              width: 174px !important;
              height: 174px !important;
            }
            > div{

            }
          }
        }
      }
    }

    .action_buttons{
      width: 1215px;
      height: 105px;
      margin-top: 30px;

      .appc{
        float: left;
        width: 90px;
        height: 90px;
        border: 1px solid #666666;
        background-color: #666666;
        margin-left: 20px;
        border-radius: 50px;
        line-height: 90px;
        font-size: 38px;
        &:hover{
          background-color: #333333 !important;
          border-color: #D20D0F !important;
        }
        .material-icons,
        .material-icons-outlined{
          font-size: 60px;
          margin-top: 15px;
        }
        img{
          margin-top: 15px;
          height: 60px;
        }
        svg{
          font-size: 60px;
          margin: 10px auto;
        }

      }
    }

    >.imprint_container{
      position: absolute;
      z-index: 3;
      width: 1920px;
      height: 0px;

      &.active{
        height: 930px;
      }
      >.imprint_area{
        width: 1800px;
        height: 885px;

        >.imp_menu{
          height: 60px;
          padding-bottom: 10px;
          line-height: 60px;
          margin-bottom: 15px;

          >.apps_imprint{
            margin: 0 15px;
            padding: 0 15px;
            height: 60px;
            line-height: 60px;
            border-radius: 9px;
          }
        }
      }
      .imp_content {
        width: 1800px;
        height: 810px;

        >.imprint_content{
          width: 1800px !important;
          height: 810px !important;

        }
        > #imp_content_general.imprint_content{
          width: 690px !important;
        }

        #imp_content_general{
          width: 690px;
          margin: 15px auto 0 auto;
          font-size: 42px;
          text-align: center;
          line-height: 75px;

          >div{
            padding-top: 30px;
            padding-bottom: 30px;
          }
          >.mabb_logo{
            margin-top: 30px;
          }
        }
        .html_text {
          line-height: 45px;
          width: 1170px;
          padding-left: 15px;
          padding-right: 15px;
          margin-left: auto;
          margin-right: auto;
          text-align: justify;

          h1{
            margin-top: 15px;
            margin-bottom: 22px;
          }
          strong{
            margin: 15px 0;
          }
          p{
            margin-bottom: 22px;
          }
        }
        .imprint_QrContainer{
          width: 1200px;
          margin: 0 auto;
          overflow: hidden;
        }

        .imprint_QrCode{
          margin-top: 45px;
          width: 675px;
          height: 630px;

        }
        .imprint_QrCode > img {
          height: 600px;
        }
        .imprint_QrCode > canvas {
          right: 120px;
          width: 450px !important;
          height: 450px !important;
        }
        .imprint_QrText {
          margin-top: 45px;
          position: relative;
          float: left;
          width: 450px;
          text-align: right;
          overflow: hidden;
        }
        .imprint_QrText span {
          font-size: 45px;
        }
        .imprint_QrText > img {
          margin-top: 15px;
          width: 300px;
        }

        .imprint_qr_tittle{
          margin-top: 75px;
          text-align: center;
        }
      }
    }
  }
}
