.news_text_container {
  background-color: #f1f1f1;
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  width: 1280px;
  height: 720px;

  .topContainer {
    position: relative;
    width: 1160px;
    height: 300px;
    padding-top: 30px;
    padding-left: 60px;
    padding-right: 60px;
    overflow: hidden;

    .topLeft{
      vertical-align: top;
      float: left;
      width: 690px;
      height: 300px;
      overflow: hidden;
      > .catTitle{
        display: inline-block;
        background-color: #D20D0F;
        color: #FFFFFF;
        padding: 5px;
      }
      > .itemTitle{
        display: block;
        font-size: 22px;
        line-height: 26px;
        font-weight: bold;
        margin-top: 10px;
      }
      > .itemSubtitle {
        margin-top: 10px;
        line-height: 20px;
      }
      > .datum{
        margin-top: 10px;
      }
      > .divider{
        display: block;
        width: 50px;
        height: 5px;
        background-color: #D20D0F;
        margin-top: 10px;
        margin-bottom: 10px;
      }
      > .actions {
        margin-top: 15px;
        height: 40px;
        > .icon {
          display: inline-block;
          padding: 0;
          margin-right: 20px;
          height: 40px;
          .material-icons,
          .material-icons-outlined{
            font-size: 48px;
            color: #808080;
          }
          svg{
            color: #808080;
            font-size: 48px;
          }
          &:hover {
            svg{
              color: #D20D0F;
            }
            .material-icons,
            .material-icons-outlined{
              color: #D20D0F;
            }
          }
          &.icon_active{
            svg{
              color: #D20D0F;
            }
            .material-icons,
            .material-icons-outlined{
              color: #D20D0F;
            }
          }
        }
      }
    }
    .topRight {
      vertical-align: top;
      float: right;
      width: 450px;
      height: 300px;
      overflow: hidden;
      height: 300px;
      max-height: 300px;
      img{
        vertical-align: top;
        max-height: 300px;
      }
    }
  }
  .bottomContainer{
    height: 340px;
    margin-bottom: 30px;
    margin-top: 20px;
    width: 1160px;
    padding-left: 60px;
    padding-right: 60px;
    overflow: hidden;
  }
}