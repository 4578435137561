#player,#av-player{
  position: absolute;
  z-index: 0;
  width: 1280px;
  height: 720px;
  overflow: hidden;

  .react-player{
    position: relative;
    width: 1280px;
    height: 720px;
    overflow: hidden;
  }

  #playerOverlay{
    position:absolute;
    top:0;
    left:0;
    z-index: 1;
    width: 1280px;
    height: 720px;
  }
  .icon svg{
    font-size: 48px;
    color: #F1F1F1;
  }
  .icon img{
    height: 48px;
  }

  .icon img.normal{
    display: block;
  }
  .icon img.active{
    display: none;
  }
  .icon.controls svg{
    font-size: 90px;
  }
  .icon.icon_active svg,
  .icon:hover svg{
    color: #D20D0F;
  }
  .icon.icon_active img.normal{
    display: none;
  }
  .icon.icon_active img.active{
    display: block;
  }

  #titleContainer{
    height:200px;
    padding:30px 60px 0 60px;
    width:1160px;
    background-image:linear-gradient(#000,rgba(0,0,0,0));

    .playerCatTitle{
      font-size: 20px;
      color: #D20D0F;
      margin-bottom: 10px;
    }
    .videoTitle{
      color:#f1f1f1;
      width:1160px;
      line-height:30px;
      font-size:30px;
      font-weight:700;
      margin-bottom:30px
    }
    #infoButtons{
      height: 40px;
      > .icon{
        display: inline-block;
        padding: 0;
        margin-right: 20px;
        height: 40px;

        .material-icons,
        .material-icons-outlined{
          font-size: 48px;
          color: #f1f1f1;
        }

        img{
          height: 40px;
        }

        svg{
          color: #F1F1F1;
          font-size: 48px;
        }
        .active_icon{
          display: none;
        }

        &:hover{
          svg{
            color: #D20D0F;
          }
          .material-icons,
          .material-icons-outlined{
            color: #D20D0F;
          }
          .normal_icon{
            display: none;
          }
          .active_icon{
            display: block;
          }
        }
        &.icon_active{
          svg{
            color: #D20D0F;
          }
          .material-icons,
          .material-icons-outlined{
            color: #D20D0F;
          }
          .normal_icon{
            display: none;
          }
          .active_icon{
            display: block;
          }
        }
      }
    }
  }

  .controlsContainer{
    position: relative;
    height: 185px;
    width: 1280px;

    #controlbar {
      position: relative;
      width: 1160px;
      height: 80px;
      margin-bottom: 30px;
      margin-left: 60px;
      text-align: center;
      > .icon{
        display: inline-block;
        img{
          height: 70px;
          margin-left: 30px;
          margin-right: 30px;
        }
        .material-icons,
        .material-icons-outlined{
          font-size: 90px;
          color: #f1f1f1;
          margin-left: 20px;
          margin-right: 20px;
        }
        .active_icon{
          display: none;
        }

        &:hover,
        &.icon_hover{
          .material-icons,
          .material-icons-outlined{
            color: #D20D0F;
          }
          .normal_icon{
            display: none;
          }
          .active_icon{
            display: block;
          }
        }

        &.icon_active{
          .material-icons,
          .material-icons-outlined{
            color: #D20D0F;
          }
          .normal_icon{
            display: none;
          }
          .active_icon{
            display: block;
          }
        }

      }
    }
  }

  .timeContainer {
    height: 40px;
    margin-left: 60px;
    width: 1160px;
    background-color: transparent;
    color: #fff;
    position: relative;
    margin-bottom: 15px;

    >div {
      height: 40px;
      line-height: 40px;
      vertical-align: top;
      display: inline-block;
    }
    #currentTime {
      color: #F1F1F1;
      width: 70px;
    }
    .timeBarContainer {
      width: 1010px;
      overflow: hidden;
      #barloaded{
        background-color: rgba(255,255,255,0.6);
        width: 0%;
      }

      #positionCirle {
        position: absolute;
        left: 0%;
        top: -9px;
        height: 20px;
        width: 20px;
        border-radius: 20px;
        background-color: #FFFFFF;
      }

      &.timeContainer_active{
        #baroverlay,
        #positionCirle{
          background-color: #D20D0F;
        }
      }

      #bar {
        margin: 19px 5px;
        height: 4px;
        width: 986px;
        background-color: #666666;
        position: relative;

        #baroverlay,
        #barloaded
        {
          position: absolute;
          left: 0;
          top: 0;
          height: 4px;
          width: 0%;
          background-color: #FFFFFF;
        }
        #barloaded{
          background-color: rgba(255,255,255,0.6);
          width: 0%;
        }
      }
    }
    #totalTime {
      width: 65px;
      margin-left: 10px;
      text-align: right;
    }
  }

  #playerBottomContainer{
    position: relative;
    margin-top: 120px;
    background-image:linear-gradient(rgba(0,0,0,0),#000);
    width: 1280px;
    min-height: 600px;
    -webkit-transition:margin-top, 0.2s linear;
    -moz-transition: margin-top, 0.2s linear;
    -ms-transition: margin-top, 0.2s linear;
    -o-transition: margin-top, 0.2s linear;
    transition: margin-top, 0.2s linear;

    #moreContainer{
      position: relative;
      width: 1280px;
      overflow: hidden;
      height: 300px;


      .grid_content_header{
        width: 1160px;
        margin-left: 60px;
        color: #F1F1F1;
        font-weight: 700;
        margin-bottom: 10px;
      }

      .similar_content{
        margin-left: 60px;
        transition: margin-left linear 0.2s;
      }

      .grid{
        .grid_content{
          background-color: rgba(255,255,255,0.6);
        }
        &.grid_active{
          .grid_content{
            background-color: rgba(255,255,255,1);
          }
        }
      }


    }
  }

  .videoloader{
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: -9px;
    margin-left: -27px;
  }

  .nextVideoContainer{
    position: absolute;
    z-index: 100;
    width: 300px;
    height: 170px;
    bottom: 30px;
    right: 60px;
    > div{
      position: relative;

      > .nextarea_item{
        position: relative;
        width: 300px;
        height: 170px;
        border-radius: 2px;
        border: 2px solid transparent;
        &.nextarea_item_active{
          border-color: #D20D0F !important;
        }
      }

      > div > .grid_img{
        position: relative;
        width: 300px;
        height: 170px;
        img{
          height: 170px;
          width: 300px;
        }
      }
      > div > .overlay{
        position: absolute;
        top: 0;
        left: 0;
        width: 300px;
        height: 170px;
        z-index: 10;
        color: #f1f1f1;
        background-color: rgba(0,0,0,0.5);
        text-align: center;
        overflow: hidden;
        > .countdown_title{
          position: relative;
          margin-top: 120px;
          margin-bottom: 15px;
        }
        > .countdown_actions{
          position: relative;
          height: 50px;
          border: 0px solid yellow;
          padding: 0;

          > .nextarea_item{
            display: inline-block;
            margin-left: 10px;
            padding-left: 5px;
            padding-right: 5px;
            margin-right: 10px;
            height: 30px;
            line-height: 30px;
            font-size: 16px;
            background-color: #f1f1f1;
            color: #666666;
            box-sizing: border-box;
            position: relative;
            user-select: none;
            cursor: pointer;
            white-space: nowrap;
            border-radius: 3px;
            transform: translate3d(0,0,0);
            transition: background 400ms cubic-bezier(0.25, 0.8, 0.25, 1),box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1)
          }
        }
      }
    }
  }
}
@media screen and (min-width: 1920px){
  #player, #av-player{
    width: 1920px;
    height: 1080px;
    .react-player{
      width: 1920px;
      height: 1080px;
    }
    #playerOverlay{
      width: 1920px;
      height: 1080px;
    }
    .icon svg{
      font-size: 72px;
    }
    .icon.controls svg{
      font-size: 135px;
    }
    #titleContainer{
      height:300px;
      width:1800px;

      .playerCatTitle{
        font-size: 30px;
        margin-bottom: 15px;
      }
      .videoTitle{
        width:1800px;
        line-height:45px;
        font-size:45px;
        font-weight:700;
        margin-bottom:45px
      }
      #infoButtons{
        height: 60px;
        > .icon{
          margin-right: 30px;
          height: 60px;
          .material-icons,
          .material-icons-outlined{
            font-size: 72px;
          }
          img{
            height: 60px;
          }
          svg{
            font-size: 72px;
          }
        }
      }
    }

    .controlsContainer{
      height: 277px;
      width: 1280px;

      #controlbar {
        position: relative;
        width: 1800px;
        height: 120px;
        margin-bottom: 45px;
        margin-left: 90px;
        > .icon{
          img{
            height: 105px;
            margin-left: 45px;
            margin-right: 45px;
          }
          .material-icons,
          .material-icons-outlined{
            font-size: 135px;
            margin-left: 30px;
            margin-right: 30px;
          }
        }
      }
    }

    .timeContainer {
      height: 60px;
      margin-left: 90px;
      width: 1800px;
      margin-bottom: 22px;
      >div {
        height: 60px;
        line-height: 60px;
      }
      #currentTime {
        width: 105px;
      }
      .timeBarContainer {
        width: 1515px;
        overflow: hidden;
        #positionCirle {
          position: absolute;
          left: 0%;
          top: -13px;
          height: 30px;
          width: 30px;
          border-radius: 30px;
        }

        #bar {
          margin: 28px 7px;
          height: 6px;
          width: 1479px;

          #baroverlay,
          #barloaded
          {
            height: 6px;
          }
        }
      }
      #totalTime {
        width: 97px;
        margin-left: 15px;
        text-align: right;
      }
    }

    #playerBottomContainer{
      margin-top: 180px;
      width: 1920px;
      min-height: 900px;

      #moreContainer{
        width: 1920px;
        height: 450px;
        .grid_content_header{
          width: 1800px;
          margin-left: 90px;
          margin-bottom: 15px;
        }
        .similar_content{
          margin-left: 90px;
        }
      }
    }

    .videoloader{
      position: absolute;
      top: 50%;
      left: 50%;
      margin-top: -9px;
      margin-left: -27px;
    }

    .nextVideoContainer{
      width: 450px;
      height: 255px;
      bottom: 30px;
      right: 60px;
      > div{

        > .nextarea_item{
          width: 450px;
          height: 255px;
        }

        > div > .grid_img{
          position: relative;
          width: 450px;
          height: 255px;
          img{
            height: 255px;
            width: 450px;
          }
        }
        > div > .overlay{
          width: 450px;
          height: 255px;
          > .countdown_title{
            margin-top: 180px;
            margin-bottom: 22px;
          }
        }
      }
    }
  }
}