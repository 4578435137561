.keyboard_holder{
    padding-top: 15px;
    width: 345px;
    height: 235px;
    background-color: rgba(0,0,0,1);
    background-image: linear-gradient(315deg, rgba(0,0,0,0.7) 0%, rgba(127,140,141,0.7) 50%);
    position: relative;
}
.keyboard_holder.keyboard_right  .keyboard_textarrow{
    position: absolute;
    left: -28px;
    top: 65px;
    fill: #666666;
    color: #666666;
    font-size: 48px;
}
.keyboard_holder.keyboard_left  .keyboard_textarrow{
    position: absolute;
    right: -28px;
    top: 65px;
    fill: #666666;
    color: #666666;
    font-size: 48px;
}
.char {
    display: inline;
    float: left;
    width: 27px;
    height: 27px;
    line-height: 27px;
    font-size: 24px;
    border: 4px solid transparent;
    text-align: center;
    margin: 1px;
    color: #F0F0F0;
}
.char.search{
    width: 70px;
    font-size: 24px;
    text-align: center;
    background-color: #F0F0F0;
    color: #333333;
}
.char.search svg,
.char.search .material-icons,
.char.search .material-icons-outlined{
    font-size: 30px;
}

.char img{
    width: 24px;
    height: 24px;
    margin-top: 2px;
    margin-right: 2px;
}
.char.search .searchNormal{
    display: inline;
}
.char.search .searchActive{
    display: none;
}
.char.char_active{
    border-color: #D20D0F;
    border-radius: 25px;
    color: #F8F8F6;
    background-color:#D20D0F;
    font-weight: 500;
}

.char.search.char_active{
    background-color:#D20D0F;
}

.char.char_active .searchNormal{
    display: none;
}
.char.char_active .searchActive{
    display: inline;
}

.char:hover{
    border-color: #D20D0F;
    border-style: dashed;
    color: #F8F8F6;
    font-weight: 500;
}
.char.search:hover .material-icons,
.char.search:hover .material-icons-outlined{
    color: #333333;
}

.char.search:hover .searchNormal{
    display: inline;
}
.char.search:hover .searchActive{
    display: none;
}

@media screen and (min-width: 1920px){
    .keyboard_holder{
        padding-top: 22px;
        width: 517px;
        height: 352px;
    }
    .keyboard_holder.keyboard_right  .keyboard_textarrow{
        position: absolute;
        left: -42px;
        top: 97px;
        font-size: 72px;
    }
    .keyboard_holder.keyboard_left  .keyboard_textarrow{
        position: absolute;
        right: -42px;
        top: 97px;
        font-size: 72px;
    }
    .char {
        width: 45px;
        height: 44px;
        line-height: 45px;
        font-size: 36px;
        border: 4px solid transparent;
        text-align: center;
        margin: 1px;
        color: #F0F0F0;
    }
    .char.search{
        width: 105px;
        font-size: 36px;
        text-align: center;
        background-color: #F0F0F0;
        color: #333333;
    }
    .char.search svg,
    .char.search .material-icons,
    .char.search:hover .material-icons-outlined{
        font-size: 45px;
    }
}