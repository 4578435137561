.exit_popup_container{
  position: absolute;
  left: 0;
  top: 0;
  width: 1280px;
  height: 720px;
  z-index: 8888;
  background: rgba(0,0,0,0.5);
  transition: all ease-in-out 0.5s;
  display: block;
  text-align: center;

  > .popup{
    margin-top: 290px;
    position: relative;
    margin-left: auto;
    margin-right: auto;
    padding: 20px;
    border-radius: 10px;
    height: 100px;
    background-color: #F0F0F0;
    display: inline-block;
    > div{
      height: 100px;
      vertical-align: top;
      display: inline-block;
      &.message {
        line-height: 100px;
        margin-right: 30px;
      }
      &.actions{
        width: 100px;
        > div {
          display: block;
          height: 30px;
          line-height: 30px;
          padding: 5px;
          margin: 5px 0;
          background-color: #666666;
          border-color: #666666;
          color: #F0F0F0;
          border-radius: 4px;
          opacity: 0.7;
          &:hover{
            background-color: #333333;
            border-color: #D20D0F;
            opacity: 1;
          }
          &.button_active{
            background-color: #D20D0F;
            border-color: #D20D0F;
            opacity: 1;
            &:hover{
              border-color: #333333;
            }
          }
        }
      }
    }

  }
}

@media screen and (min-width: 1920px){
  .exit_popup_container{
    width: 1920px;
    height: 1080px;

    > .popup{
      margin-top: 435px;
      padding: 30px;
      border-radius: 15px;
      height: 150px;
      > div{
        height: 150px;
        &.message {
          line-height: 150px;
          margin-right: 40px;
        }
        &.actions{
          width: 150px;
          > div {
            height: 45px;
            line-height: 45px;
            padding: 7px;
            margin: 8px 0;
            border-radius: 6px;
          }
        }
      }

    }
  }
}